<template>
    <div class="page_container">
        <div class="page_title">Settings</div>
        <div class="page_content">
            <b-tabs content-class="mt-3">
                <b-tab title="Subscription management/Billing" active>
                    <div class="page_tab-content">
                        <div class="billing_container">
                            <div class="billing_switch">
                                <b-form-group
                                        v-slot="{ ariaDescribedby }"
                                >
                                    <b-form-radio-group
                                            v-model="selectedPlan"
                                            :options="planOptions"
                                            :aria-describedby="ariaDescribedby"
                                            button-variant="outline-primary"
                                            size="lg"
                                            name="radio-btn-outline"
                                            buttons
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="billing_plan-container">
                            <div class="billing_plan-item">
                                <div class="billing_plan-item_head">
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_title">Core</div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_price">$27<span>/Month</span></div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                </div>
                                <div class="billing_plan-item_body">
                                    <b-button :class="{'active': activePlan == 'Core'}" @click="changePlan('Core')">{{
                                        activePlan == 'Core' ? 'Active': 'Upgrade'}}
                                    </b-button>
                                    <div class="billing_plan-text_content">
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            1 Compaign
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            1 IG Account
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Up to 500 Messages
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Up to 1000 Leads Database Slots
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Anlytics Dshboard
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="billing_plan-item">
                                <div class="billing_plan-item_head">
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_title">Pro</div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_price">$37<span>/Month</span></div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                </div>
                                <div class="billing_plan-item_body">
                                    <b-button :class="{'active': activePlan == 'Pro'}" @click="changePlan('Pro')">{{
                                        activePlan == 'Pro' ? 'Active': 'Upgrade'}}
                                    </b-button>
                                    <div class="billing_plan-text_content">
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Unlimited Campaigns
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Unlimited IG Accounts
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Up to 5,000 Messages
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Up to 20,000 Leads Dastabase
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Enhanced Anlytics
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="billing_plan-item">
                                <div class="billing_plan-item_head">
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_title">Enterprise</div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                    <div class="plan_item-head_box">
                                        <div class="plan_item-box_price">$145<span>/Month</span></div>
                                        <div class="plan_item-box_subtitle">Some text</div>
                                    </div>
                                </div>
                                <div class="billing_plan-item_body">
                                    <b-button :class="{'active': activePlan == 'Enterprise'}"
                                              @click="changePlan('Enterprise')">{{ activePlan == 'Enterprise' ?
                                        'Active': 'Upgrade'}}
                                    </b-button>
                                    <div class="billing_plan-text_content">
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Unlimited Campaigns
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Unlimited IG Accounts
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Unlimited Messages
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Up to 400,000 Leads Database
                                        </div>
                                        <div class="billing_plan-text_item">
                                            <div class="billing_plan-text_mark"></div>
                                            Private Support
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Personal Details">
                    <div class="page_tab-content">
                        <div class="personal_details-content">
                            <div class="personal_details-box">
                                <div class="personal_details-image">
                                    <div class="personal_details-edit">
                                        <svg @click="editAvatar()" width="25" height="24" viewBox="0 0 25 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.82874 19.1866L5.18183 18.834L4.82875 19.1866C5.038 19.3962 5.29905 19.3999 5.45379 19.3849C5.59434 19.3712 5.76172 19.3295 5.91844 19.2903C5.92737 19.2881 5.93627 19.2859 5.94513 19.2837L8.61293 18.6189L8.61294 18.619L8.61689 18.6179C8.63064 18.6145 8.64431 18.6111 8.65787 18.6077C8.88028 18.5525 9.07717 18.5037 9.25603 18.4027C9.4349 18.3017 9.57842 18.1584 9.74054 17.9964C9.75138 17.9856 9.7623 17.9747 9.77333 17.9637L16.9898 10.7706L17.0132 10.7474C17.3281 10.4335 17.5994 10.1632 17.7874 9.91748C17.9888 9.65422 18.1415 9.36126 18.1415 9C18.1415 8.63874 17.9888 8.34578 17.7874 8.08252C17.5994 7.83685 17.3281 7.56649 17.0132 7.25265L16.9898 7.22936L16.8131 7.0532L16.4601 7.40733L16.8131 7.0532L16.7898 7.03C16.4769 6.71803 16.2072 6.44917 15.9622 6.26282C15.6996 6.06308 15.4077 5.91192 15.0482 5.91192C14.6887 5.91192 14.3968 6.06308 14.1342 6.26282C13.8892 6.44917 13.6195 6.71804 13.3066 7.03002L13.2833 7.0532L6.05891 14.2541C6.04785 14.2652 6.03687 14.2761 6.02597 14.2869C5.86297 14.4491 5.71867 14.5926 5.61693 14.7718C5.51518 14.951 5.46588 15.1484 5.41019 15.3715C5.40646 15.3864 5.40271 15.4014 5.39891 15.4166L4.73338 18.0701C4.73116 18.0789 4.72892 18.0878 4.72668 18.0968C4.68731 18.2534 4.64528 18.4207 4.63142 18.5612C4.61617 18.716 4.61949 18.977 4.82874 19.1866Z"
                                                  stroke="white"/>
                                            <path d="M12.541 7.5L15.5508 5.5L18.5606 8.5L16.554 11.5L12.541 7.5Z"
                                                  fill="white"/>
                                        </svg>
                                        <input type="file" ref="userImg" @change="onFileChange"/>
                                    </div>
                                    <div v-if="userForm.avatar" class="avatar_remove">
                                        <svg @click="removeAvatar()" width="25" height="24" viewBox="0 0 25 24"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.53101 14.5L9.53101 11.5" stroke="#ffffff"
                                                  stroke-linecap="round"/>
                                            <path d="M14.5472 14.5L14.5472 11.5" stroke="#ffffff"
                                                  stroke-linecap="round"/>
                                            <path d="M3.00977 6.5H21.0684V6.5C19.6531 6.5 18.9454 6.5 18.4384 6.84205C18.2257 6.98557 18.0426 7.1687 17.899 7.38143C17.557 7.88841 17.557 8.59608 17.557 10.0114V15.5C17.557 17.3856 17.557 18.3284 16.9712 18.9142C16.3854 19.5 15.4426 19.5 13.557 19.5H10.5212C8.63555 19.5 7.69274 19.5 7.10695 18.9142C6.52117 18.3284 6.52117 17.3856 6.52117 15.5V10.0114C6.52117 8.59608 6.52117 7.88841 6.17911 7.38143C6.03559 7.1687 5.85247 6.98557 5.63974 6.84205C5.13275 6.5 4.42509 6.5 3.00977 6.5V6.5Z"
                                                  stroke="#ffffff" stroke-linecap="round"/>
                                            <path d="M9.53095 3.50024C9.53095 3.50024 10.0326 2.5 12.0391 2.5C14.0456 2.5 14.5472 3.5 14.5472 3.5"
                                                  stroke="#ffffff" stroke-linecap="round"/>
                                        </svg>

                                    </div>
                                    <div class="cover_img" :style="{ backgroundImage: 'url(' + userImg + ')' }"></div>
                                    <img :src="userForm.avatar ? userForm.avatar: emptyImg">
                                </div>
                                <div class="personal_details-data">
                                    <div class="personal_details-data_title">Personal Details</div>

                                    <div class="input_item">
                                        <label>Name</label>
                                        <b-form-input
                                                v-model="userForm.fullName"
                                                type="text"
                                                trim
                                        >
                                            <!--                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.fullName.$error }"-->
                                        </b-form-input>
                                        <!--                                          <div v-if="isSubmitted && $v.userForm.fullName.$error" class="invalid-feedback">-->
                                        <!--                                              <span v-if="!$v.userForm.fullName.required">Name field is required</span>-->
                                        <!--                                          </div>-->
                                    </div>

                                    <div class="input_item">
                                        <label>Email</label>
                                        <b-form-input
                                                id="email"
                                                v-model="userForm.email"
                                                type="email"
                                                disabled
                                        >
                                        </b-form-input>
                                        <!--                                          <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">-->
                                        <!--                                              <span v-if="!$v.userForm.email.required">Email field is required</span>-->
                                        <!--                                              <span v-if="!$v.userForm.email.email">Please provide valid email</span>-->
                                        <!--                                          </div>-->
                                    </div>
                                    <div class="input_item">
                                        <label>Password</label>
                                        <b-form-input
                                                placeholder="******"
                                                type="password"
                                                v-model="userForm.password"
                                                disabled
                                        ></b-form-input>
                                        <!--                                          <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text'}"-->
                                        <!--                                               @click="showPassword('r_pass')"></div>-->
                                        <!--                                          <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">-->
                                        <!--                                              <span v-if="!$v.userForm.password.required">Password field is required</span>-->
                                        <!--                                              <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>-->
                                        <!--                                          </div>-->
                                    </div>
                                    <div class="change_pass" id="change-btn" @click="changePassModal()">Change
                                        password
                                    </div>
                                    <b-button class="save_btn" @click="saveChanges()">Save changes</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--change password modal-->
                    <b-modal ref="change_pass-modal" centered size="md" hide-header hide-footer>
                        <div class="modal_content">
                            <div class="modal_title">
                                Change password
                            </div>
                            <form @submit.prevent="changePassword()">

                                <div class="modal_input">
                                    <div class="input_item">
                                        <label>Old password</label>
                                        <b-form-input
                                                v-model="changePassData.oldPassword"
                                                type="password"
                                                :class="{ 'is-invalid': isSubmitted && $v.changePassData.oldPassword.$error }"
                                                trim
                                        ></b-form-input>
                                        <div v-if="isSubmitted && $v.changePassData.oldPassword.$error"
                                             class="invalid-feedback">
                                            <span v-if="!$v.changePassData.oldPassword.required">Old password field is required</span>
                                            <span v-if="!$v.changePassData.oldPassword.minLength">Old password should be at least 6 characters long</span>
                                        </div>
                                    </div>
                                    <div class="input_item">
                                        <label>New password</label>
                                        <b-form-input
                                                v-model="changePassData.newPassword"
                                                type="password"
                                                :class="{ 'is-invalid': isSubmitted && $v.changePassData.newPassword.$error }"
                                                trim
                                        ></b-form-input>
                                        <div v-if="isSubmitted && $v.changePassData.newPassword.$error"
                                             class="invalid-feedback">
                                            <span v-if="!$v.changePassData.newPassword.required">New password field is required</span>
                                            <span v-if="!$v.changePassData.newPassword.minLength">New password should be at least 6 characters long</span>
                                        </div>
                                    </div>
                                    <div class="input_item">
                                        <label>Confirm new password</label>
                                        <b-form-input
                                                v-model="changePassData.confirmNewPassword"
                                                type="password"
                                                :class="{ 'is-invalid': isSubmitted && $v.changePassData.confirmNewPassword.$error }"
                                                trim
                                        ></b-form-input>
                                        <div v-if="isSubmitted && $v.changePassData.confirmNewPassword.$error"
                                             class="invalid-feedback">
                                            <span v-if="!$v.changePassData.confirmNewPassword.required">Confirm new Password field is required</span>
                                            <span v-else-if="!$v.changePassData.confirmNewPassword.sameAsPassword">Passwords must match</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal_btn">
                                    <b-button class="cancel_btn mx-2" @click="changePassModal()">Cancel</b-button>
                                    <b-button class="change_btn mx-2" type="submit">Change Password</b-button>
                                </div>
                            </form>
                            <div v-if="successChangeMessage" class="success_message">
                                <span>{{successChangeMessage}}</span>
                            </div>
                        </div>
                    </b-modal>
                    <!--change password modal-->
                </b-tab>
                <b-tab title="My cards">
                    <div class="page_tab-content">
                        <div class="cards_container">
                            <div class="cards_title">My credit cards</div>
                            <div>
                                <b-button class="cards_btn" @click="creditCardModal('add')" id="add_card-btn">+ Add new
                                </b-button>
                            </div>
                            <div class="cards_items">
                                <div class="cards_item" v-for="card of creditCards">
                                    <div class="d-flex align-items-center">
                                        <div class="card_type"
                                             :class="{'visa': card.cardType == 'Visa', 'master': card.cardType == 'Master',}"></div>
                                        <div class="card_data">
                                            <div class="card_number">{{card.cardType}} .....{{card.card_number}}
                                                <div v-if="card.active" :class="{'card_active': card.active}">Active
                                                </div>
                                            </div>
                                            <div class="card_expire">Expires {{card.card_month}}/{{card.card_year}}</div>
                                        </div>
                                    </div>
                                    <div class="card_buttons">
                                        <div class="card_item-btn" @click="creditCardModal('edit')">
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.82874 19.1866L5.18183 18.834L4.82875 19.1866C5.038 19.3962 5.29905 19.3999 5.45379 19.3849C5.59434 19.3712 5.76172 19.3295 5.91844 19.2903C5.92737 19.2881 5.93627 19.2859 5.94513 19.2837L8.61293 18.6189L8.61294 18.619L8.61689 18.6179C8.63064 18.6145 8.64431 18.6111 8.65787 18.6077C8.88028 18.5525 9.07717 18.5037 9.25603 18.4027C9.4349 18.3017 9.57842 18.1584 9.74054 17.9964C9.75138 17.9856 9.7623 17.9747 9.77333 17.9637L16.9898 10.7706L17.0132 10.7474C17.3281 10.4335 17.5994 10.1632 17.7874 9.91748C17.9888 9.65422 18.1415 9.36126 18.1415 9C18.1415 8.63874 17.9888 8.34578 17.7874 8.08252C17.5994 7.83685 17.3281 7.56649 17.0132 7.25265L16.9898 7.22936L16.8131 7.0532L16.4601 7.40733L16.8131 7.0532L16.7898 7.03C16.4769 6.71803 16.2072 6.44917 15.9622 6.26282C15.6996 6.06308 15.4077 5.91192 15.0482 5.91192C14.6887 5.91192 14.3968 6.06308 14.1342 6.26282C13.8892 6.44917 13.6195 6.71804 13.3066 7.03002L13.2833 7.0532L6.05891 14.2541C6.04785 14.2652 6.03687 14.2761 6.02597 14.2869C5.86297 14.4491 5.71867 14.5926 5.61693 14.7718C5.51518 14.951 5.46588 15.1484 5.41019 15.3715C5.40646 15.3864 5.40271 15.4014 5.39891 15.4166L4.73338 18.0701C4.73116 18.0789 4.72892 18.0878 4.72668 18.0968C4.68731 18.2534 4.64528 18.4207 4.63142 18.5612C4.61617 18.716 4.61949 18.977 4.82874 19.1866Z"
                                                      stroke="white"/>
                                                <path d="M12.541 7.5L15.5508 5.5L18.5606 8.5L16.554 11.5L12.541 7.5Z"
                                                      fill="white"/>
                                            </svg>
                                        </div>
                                        <div class="card_item-btn" id="remove_card-btn" @click="removeCardModal()">
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.53101 14.5L9.53101 11.5" stroke="white"
                                                      stroke-linecap="round"/>
                                                <path d="M14.5472 14.5L14.5472 11.5" stroke="white"
                                                      stroke-linecap="round"/>
                                                <path d="M3.00977 6.5H21.0684V6.5C19.6531 6.5 18.9454 6.5 18.4384 6.84205C18.2257 6.98557 18.0426 7.1687 17.899 7.38143C17.557 7.88841 17.557 8.59608 17.557 10.0114V15.5C17.557 17.3856 17.557 18.3284 16.9712 18.9142C16.3854 19.5 15.4426 19.5 13.557 19.5H10.5212C8.63555 19.5 7.69274 19.5 7.10695 18.9142C6.52117 18.3284 6.52117 17.3856 6.52117 15.5V10.0114C6.52117 8.59608 6.52117 7.88841 6.17911 7.38143C6.03559 7.1687 5.85247 6.98557 5.63974 6.84205C5.13275 6.5 4.42509 6.5 3.00977 6.5V6.5Z"
                                                      stroke="white" stroke-linecap="round"/>
                                                <path d="M9.53095 3.50024C9.53095 3.50024 10.0326 2.5 12.0391 2.5C14.0456 2.5 14.5472 3.5 14.5472 3.5"
                                                      stroke="white" stroke-linecap="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!--add card modal-->
                    <b-modal ref="add_card-modal" centered size="md" hide-header hide-footer>
                        <div class="modal_content">
                            <div class="modal_title">
                                {{card_modal.title}} card
                            </div>
                            <div class="modal_subtitle">Card information</div>
                            <div class="modal_cards">
                                <div class="card_c visa"></div>
                                <div class="card_c master"></div>
                            </div>
                            <form @submit.prevent="addCard()">
                                <div class="modal_input">
                                    <div class="input_item number">
                                        <b-form-input
                                                v-model="card_data.card_number"
                                                placeholder="Card number"
                                                onKeyPress="if(this.value.length==16) return false;"
                                                :class="{ 'is-invalid': isSubmitted && $v.card_data.card_number.$error }"
                                                trim
                                        ></b-form-input>

<!--                                        <div v-if="isSubmitted && $v.changePassData.oldPassword.$error" class="invalid-feedback">-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.required">Old password field is required</span>-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.minLength">Old password should be at least 6 characters long</span>-->
<!--                                        </div>-->
                                    </div>

                                    <div class="input_item dual_input">
                                        <div class="mini_inputs">
                                            <b-form-input
                                                    v-model="card_data.card_month"
                                                    placeholder="MM"
                                                    type="number"
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    onKeyPress="if(this.value.length==2) return false;"
                                                    :class="{ 'is-invalid': isSubmitted && $v.card_data.card_month.$error }"
                                                    trim
                                            ></b-form-input>
                                            <b-form-input
                                                    v-model="card_data.card_year"
                                                    placeholder="YY"
                                                    type="number"
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    onKeyPress="if(this.value.length==2) return false;"
                                                    :class="{ 'is-invalid': isSubmitted && $v.card_data.card_year.$error }"
                                                    trim
                                            ></b-form-input>
                                        </div>

                                        <b-form-input
                                                v-model="card_data.card_cvc"
                                                placeholder="CVV/CVC"
                                                type="password"
                                                pattern="/^-?\d+\.?\d*$/"
                                                onKeyPress="if(this.value.length==3) return false;"
                                                :class="{ 'is-invalid': isSubmitted && $v.card_data.card_cvc.$error }"
                                                trim
                                        ></b-form-input>
<!--                                        <div v-if="isSubmitted && $v.changePassData.oldPassword.$error" class="invalid-feedback">-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.required">Old password field is required</span>-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.minLength">Old password should be at least 6 characters long</span>-->
<!--                                        </div>-->
                                    </div>
                                    <div class="input_item">
                                        <b-form-input
                                                v-model="card_data.card_name"
                                                placeholder="Holder name"
                                                onKeyPress="if(this.value.length==26) return false;"
                                                type="text"
                                                :class="{ 'is-invalid': isSubmitted && $v.card_data.card_name.$error }"
                                                trim
                                        ></b-form-input>
<!--                                        <div v-if="isSubmitted && $v.changePassData.oldPassword.$error" class="invalid-feedback">-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.required">Old password field is required</span>-->
<!--                                            <span v-if="!$v.changePassData.oldPassword.minLength">Old password should be at least 6 characters long</span>-->
<!--                                        </div>-->
                                    </div>
<!--                                    <div class="input_item">-->
<!--                                        <label>New password</label>-->
<!--                                        <b-form-input-->
<!--                                                v-model="changePassData.newPassword"-->
<!--                                                type="password"-->
<!--                                                :class="{ 'is-invalid': isSubmitted && $v.changePassData.newPassword.$error }"-->
<!--                                                trim-->
<!--                                        ></b-form-input>-->
<!--                                        <div v-if="isSubmitted && $v.changePassData.newPassword.$error" class="invalid-feedback">-->
<!--                                            <span v-if="!$v.changePassData.newPassword.required">New password field is required</span>-->
<!--                                            <span v-if="!$v.changePassData.newPassword.minLength">New password should be at least 6 characters long</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="input_item">-->
<!--                                        <label>Confirm new password</label>-->
<!--                                        <b-form-input-->
<!--                                                v-model="changePassData.confirmNewPassword"-->
<!--                                                type="password"-->
<!--                                                :class="{ 'is-invalid': isSubmitted && $v.changePassData.confirmNewPassword.$error }"-->
<!--                                                trim-->
<!--                                        ></b-form-input>-->
<!--                                        <div v-if="isSubmitted && $v.changePassData.confirmNewPassword.$error"-->
<!--                                             class="invalid-feedback">-->
<!--                                            <span v-if="!$v.changePassData.confirmNewPassword.required">Confirm new Password field is required</span>-->
<!--                                            <span v-else-if="!$v.changePassData.confirmNewPassword.sameAsPassword">Passwords must match</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                                <div class="modal_btn">
                                    <b-button class="cancel_btn mx-2" @click="creditCardModal()">Cancel</b-button>
                                    <b-button class="change_btn mx-2" type="submit">{{card_modal.button}}</b-button>
                                </div>
                            </form>
                            <!--                            <div v-if="successChangeMessage"  class="success_message">-->
                            <!--                                <span>{{successChangeMessage}}</span>-->
                            <!--                            </div>-->
                        </div>
                    </b-modal>
                    <!--add card modal-->

                    <!--delete card modal-->
                    <b-modal ref="delete_card-modal" centered size="md" hide-header hide-footer>
                        <div class="modal_content">
                            <div class="modal_title">
                                Delete card
                            </div>
                            <div class="modal_subtitle">You are about to remove this card</div>

                            <div class="modal_btn">
                                <b-button class="cancel_btn mx-2" @click="removeCardModal()">Cancel</b-button>
                                <b-button class="change_btn mx-2" @click="deleteCard()">Delete card</b-button>
                            </div>

                        </div>
                    </b-modal>
                    <!--delete card modal-->
                </b-tab>
            </b-tabs>

        </div>

    </div>
</template>

<script>
    import img from "@/assets/images/user_empty_img.svg"
    import {required, email, minLength, sameAs} from "vuelidate/lib/validators"
    import {mapActions} from "vuex";

    export default {
        name: "Settings",
        data() {
            return {
                selectedPlan: 'Monthly',
                activePlan: 'Core',
                planOptions: [
                    {text: 'Monthly', value: 'Monthly'},
                    {text: 'Yearly', value: 'Yearly'},
                ],
                creditCards: [
                    {
                        card_number: '4242',
                        card_month: '08',
                        card_year: '23',
                        card_name: 'Name Surname',
                        cardType: 'Visa',
                        active: true
                    },
                    {
                        card_number: '5524',
                        card_month: '09',
                        card_year: '25',
                        cardType: 'Master',
                        active: false
                    }
                ],
                card_data: {
                    card_number: null,
                    card_month: null,
                    card_year: null,
                    card_cvc: null,
                    card_name: null
                },
                card_modal: {
                  title: 'New',
                  button: 'Save details'
                },
                emptyImg: img,
                userImg: null,
                userForm: {
                    avatar: null,
                    changeAvatar: null,
                    fullName: null,
                    email: null,
                    password: null,
                },
                isSubmitted: false,
                changePassData: {
                    oldPassword: null,
                    newPassword: null,
                    confirmNewPassword: null,
                },
                successChangeMessage: null,
            }
        },
        validations: {
            changePassData: {
                oldPassword: {
                    required,
                    minLength: minLength(6)
                },
                newPassword: {
                    required,
                    minLength: minLength(6)
                },
                confirmNewPassword: {
                    required,
                    sameAsPassword: sameAs("newPassword")
                }
            }
        },
        created() {
            this.getUserData()
                .then(res => {
                    this.userForm.avatar = res.data.avatar
                    this.userForm.fullName = res.data.name
                    this.userForm.email = res.data.email
                })
        },
        methods: {
            ...mapActions(['getUserData', 'changeUserPass', 'changePersonalDetails']),
            changePlan(plan) {
                this.activePlan = plan
            },
            editAvatar() {
                this.$refs.userImg.click()
            },
            onFileChange(event) {
                this.userForm.changeAvatar = event.target.files[0]
                this.createImage(this.userForm.changeAvatar)
            },
            createImage(file) {
                var reader = new FileReader()
                reader.onload = event => {
                    this.userImg = event.target.result
                }
                reader.readAsDataURL(file)
            },
            changePassModal() {
                this.changePassData.oldPassword = null
                this.changePassData.newPassword = null
                this.changePassData.confirmNewPassword = null
                this.isSubmitted = false;
                this.$refs['change_pass-modal'].toggle('#change-btn')
            },
            changePassword() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                } else {
                    this.changeUserPass(this.changePassData)
                        .then(res => {
                            this.successChangeMessage = res.data.message
                            setTimeout(() => {
                                this.successChangeMessage = null
                                this.changePassModal()
                            }, 3000)
                        })


                }
            },
            saveChanges() {
                this.changePersonalDetails(this.userForm)
                    .then(res => {
                        this.getUserData()
                            .then(res => {
                                this.userForm.avatar = res.data.avatar
                                this.userForm.fullName = res.data.name
                                this.userForm.email = res.data.email
                            })
                    })
            },
            removeAvatar() {
                this.userForm.avatar = null
                this.userForm.changeAvatar = null
                this.userImg = null
            },
            creditCardModal(type) {
                if(type == 'add') {
                    this.card_modal.title =  'New'
                    this.card_modal.button = 'Save details'
                } else {
                    this.card_modal.title =  'Edit'
                    this.card_modal.button = 'Edit details'
                }
                this.$refs['add_card-modal'].toggle('#add_card-btn')
            },
            addCard() {

            },
            removeCardModal() {
                this.$refs['delete_card-modal'].toggle('#remove_card-btn')
            },
            deleteCard() {

            }
        }
    }
</script>

<style scoped lang="scss">

    .page {
        &_container {

        }

        &_title {
            padding: 15px 0 60px;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #000;
            @media screen and (max-width: 1024px) {
                padding: 15px 0 30px;
            }
        }

        &_content {
            display: flex;

            & .tabs {
                width: 100%;
                padding-right: 100px;
                @media screen and (max-width: 1280px) {
                    padding-right: 60px;
                }
                @media screen and (max-width: 1024px) {
                    padding-right: 30px;
                }
            }
        }

        &_tab {
            &-content {
                ::v-deep .billing {
                    &_container {
                        padding: 16px 0;
                        display: flex;
                        justify-content: center;
                        @media screen and (max-width: 1024px) {
                            padding: 5px 0;
                        }
                    }

                    &_switch {
                        & .btn-group-toggle {
                            background: #FFFFFF;
                            border: 1px solid #E7E7E7;
                            border-radius: 40px;
                            padding: 6px 0;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 26px;
                            color: #000;

                            & input {
                                opacity: 0 !important;
                                width: 1px;
                            }

                            & label {
                                border: none;
                                outline: none;
                                box-shadow: none;
                                margin: 0 32px;

                                & span {
                                    color: #000;
                                }

                                &.active {
                                    background: #1890FF;
                                    border-radius: 40px;
                                    /*&.btn:not(:last-child):not(.dropdown-toggle) {*/
                                    /*  background: transparent;*/
                                    /*}*/
                                    & span {
                                        color: #fff;
                                    }
                                }

                                &:hover {
                                    color: #000;
                                    border-radius: 40px;
                                    background: rgba(24, 144, 255, 0.3);
                                }

                                &:focus-visible {
                                    background: #fff;
                                    border-radius: 40px;
                                    outline: none;
                                    box-shadow: none;
                                    color: #000;
                                }
                            }
                        }

                    }

                    &_plan {
                        &-container {
                            padding-right: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                        }

                        &-item {
                            width: 400px;
                            height: 560px;
                            background: #FFFFFF;
                            box-shadow: 4px 4px 4px rgba(202, 210, 219, 0.2);
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column;
                            padding: 22px;
                            @media screen and (max-width: 1440px) {
                                width: 340px;
                                height: 480px;
                            }
                            @media screen and (max-width: 1280px) {
                                width: 320px;
                            }
                            @media screen and (max-width: 1024px) {
                                padding: 15px;
                                width: 260px;
                                height: 450px;
                                margin-bottom: 30px;
                            }

                            &_head {
                                border-bottom: 1px solid rgba(57, 57, 57, 0.5);
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 10px 0;
                                @media screen and (max-width: 1024px) {
                                    padding: 0 0 10px;
                                }

                                & .plan_item {
                                    &-head {
                                        &_box {


                                        }
                                    }

                                    &-box {
                                        &_title {
                                            font-weight: 500;
                                            font-size: 30px;
                                            line-height: 39px;
                                            color: #8103F6;
                                            padding-bottom: 8px;
                                            @media screen and (max-width: 1440px) {
                                                font-size: 24px;
                                            }
                                            @media screen and (max-width: 1024px) {
                                                font-size: 22px;
                                            }
                                        }

                                        &_subtitle {
                                            font-weight: 400;
                                            font-size: 18px;
                                            line-height: 23px;
                                            color: #393939;
                                        }

                                        &_price {
                                            font-weight: 500;
                                            font-size: 30px;
                                            line-height: 39px;
                                            color: #393939;
                                            padding-bottom: 8px;
                                            @media screen and (max-width: 1440px) {
                                                font-size: 24px;
                                            }
                                            @media screen and (max-width: 1024px) {
                                                font-size: 20px;
                                            }

                                            & span {
                                                font-size: 18px;
                                                line-height: 23px;
                                            }
                                        }

                                    }
                                }
                            }

                            &_body {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 32px 0;
                                flex-direction: column;

                                & button {
                                    width: 166px;
                                    height: 44px;
                                    background: #1890FF;
                                    border-radius: 10px;
                                    padding: 10px 46px;
                                    border: none;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &:hover {
                                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                    }

                                    &:focus {
                                        background: #8103F6;
                                    }

                                    &.active {
                                        background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                                    }

                                }

                            }
                        }

                        &-text {
                            &_content {
                                padding: 24px 0;
                                @media screen and (max-width: 1024px) {
                                    padding: 20px 5px;
                                }

                            }

                            &_item {
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 21px;
                                color: rgba(57, 57, 57, 0.5);
                                padding: 11px 0;
                                @media screen and (max-width: 1440px) {
                                    line-height: 18px;
                                }
                                @media screen and (max-width: 1024px) {
                                    padding: 10px 0;
                                }


                            }

                            &_mark {
                                width: 11px;
                                height: 13px;
                                background: url("~@/assets/images/green_mark.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: cover;
                                margin-right: 15px;
                            }
                        }
                    }
                }

                & .personal_details {
                    &-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &-box {
                        width: 520px;
                        height: 630px;
                        background: #fff;
                        border: 1px solid #E7E7E7;
                        border-radius: 8px;
                        padding: 20px;
                    }

                    &-data {
                        padding-top: 30px;

                        &_title {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 26px;
                            color: #000000;
                            padding-bottom: 16px;
                        }

                        & .input_item {
                            margin-bottom: 10px;
                            position: relative;

                            .form-control:disabled {
                                background: #fff;
                            }

                            & label {
                                color: #9E9E9E;
                                font-weight: 500;
                                line-height: 21px;
                            }

                            & input {
                                height: 50px;
                                border-radius: 10px;
                                border: 1px solid rgba(0, 0, 0, 0.1);

                                &:focus {
                                    border: 1px solid rgba(24, 144, 255, 0.3);
                                    box-shadow: none;
                                }
                            }

                            & .eye_close {
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                right: 15px;
                                top: 38px;
                                background: url("~@/assets/images/eye_open.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                cursor: pointer;
                            }

                            & .eye_open {
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                right: 15px;
                                top: 38px;
                                background: url("~@/assets/images/eye_close.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                cursor: pointer;
                            }
                        }

                        & .change_pass {
                            color: #1890FF;
                            line-height: 20px;
                            cursor: pointer;

                        }

                        & .save_btn {
                            margin: 20px auto;
                            text-align: center;
                            padding: 14px 0;
                            background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                            border-radius: 10px;
                            border: none;
                            width: 130px;
                            height: 50px;

                            &:hover {
                                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            }

                            &:focus {
                                background: #8103F6;
                            }
                        }

                    }

                    &-image {
                        width: 122px;
                        height: 122px;
                        border-radius: 50%;
                        position: relative;

                        &:hover {
                            .avatar_remove {
                                visibility: visible;
                                opacity: 1;
                                transition: .4s;
                            }
                        }

                        & input {
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            cursor: pointer;
                        }

                        & .cover_img {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border-radius: 50%;
                            background: no-repeat center / cover;
                        }

                        & .avatar_remove {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 37px;
                            height: 37px;
                            background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                            border: 3px solid #FFFFFF;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            visibility: hidden;
                            opacity: 0;
                            transition: .4s;
                            cursor: pointer;
                            z-index: 2;

                            & svg {
                                cursor: pointer;
                            }
                        }

                        & img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                    }

                    &-edit {
                        position: absolute;
                        width: 37px;
                        height: 37px;
                        background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                        border: 3px solid #FFFFFF;
                        border-radius: 50%;
                        top: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        z-index: 5;
                    }
                }

                & .cards {
                    &_container {
                        margin: 35px auto;
                        max-width: 820px;
                        width: 100%;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        border-radius: 8px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                    }

                    &_title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 26px;
                        color: #000000;
                    }

                    &_btn {
                        margin: 16px 0;
                        width: 130px;
                        height: 50px;
                        background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                        border-radius: 10px;
                        border: none;

                        &:hover {
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                        }

                        &:focus {
                            background: #8103F6;
                            box-shadow: none;
                        }
                    }

                    &_items {
                        display: flex;
                        flex-direction: column;
                    }

                    &_item {
                        max-width: 780px;
                        width: 100%;
                        height: 88px;
                        background: #FFFFFF;
                        border: 1px solid #9E9E9E;
                        border-radius: 8px;
                        margin: 8px 0;
                        padding: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        & .card {
                            &_type {
                                width: 41px;
                                height: 26px;
                                background-position: center;
                                background-repeat: no-repeat;

                                &.visa {
                                    background: url("~@/assets/images/card_visa.png");
                                }

                                &.master {
                                    background: url("~@/assets/images/card_master.png");
                                }

                            }

                            &_data {
                                padding: 0 60px;

                            }

                            &_number {
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 23px;
                                color: #393939;
                                padding-bottom: 5px;
                                display: flex;
                                align-items: center;

                            }

                            &_expire {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 21px;
                                color: #919395;

                            }

                            &_active {
                                width: 63px;
                                height: 34px;
                                background: #E8FFF3;
                                border-radius: 20px;
                                font-size: 12px;
                                line-height: 16px;
                                color: #1DB380;
                                margin: 0 6px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                            }

                            &_buttons {
                                display: flex;
                                align-items: center;

                            }

                            &_item-btn {
                                width: 42px;
                                height: 42px;
                                background: #8103F6;
                                border-radius: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-left: 8px;
                                cursor: pointer;

                                &:hover {
                                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                }

                                &:focus {
                                    background: #8103F6;
                                }
                            }

                        }
                    }
                }
            }

        }
    }

    .modal {
        &_content {
            padding: 18px;
        }

        &_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #000000;
        }

        &_subtitle {
            padding: 32px 0 24px;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #393939;
        }

        &_cards {
            display: flex;
            align-items: center;

            & .card_c {
                width: 41px;
                height: 26px;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 10px;

                &.visa {
                    background: url("~@/assets/images/card_visa.png");
                }

                &.master {
                    background: url("~@/assets/images/card_master.png");
                }
            }
        }

        &_input {
            padding: 30px 0;

            & .input_item {
                margin-bottom: 10px;

                & label {
                    color: #9E9E9E;
                    font-weight: 500;
                    line-height: 21px;
                }

                & input {
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    &:focus {
                        border: 1px solid rgba(24, 144, 255, 0.3);
                        box-shadow: none;
                    }
                }

                &.dual_input {
                    display: flex;
                    align-items: center;

                    & input {
                        width: 152px;
                        margin-right: 8px;
                    }

                    & .mini_inputs {
                        display: flex;
                        align-items: center;

                        & input {
                            width: 60px;
                        }
                    }
                }

            }
        }

        &_btn {
            display: flex;
            justify-content: center;
            align-items: center;

            & .cancel_btn {
                border: 1px solid rgba(57, 57, 57, 0.5);
                border-radius: 10px;
                height: 50px;
                width: 208px;
                background: #fff;
                color: rgba(57, 57, 57, 0.5);

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    border: 1px solid #393939;
                    color: #393939;
                }
            }

            & .change_btn {
                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                border-radius: 10px;
                height: 50px;
                width: 208px;
                border: none;

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    background: #8103F6;
                    box-shadow: none;
                }

            }
        }

    }

    ::v-deep .nav-tabs {
        & .nav-link {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #000;

            &:hover {
                border-color: transparent;
            }

            &.active {
                background-color: transparent;
                border-color: transparent;
                border-bottom: 2px solid #1890FF;
            }
        }
    }

    ::v-deep .is-invalid {
        background-image: none !important;
        border: 1px solid rgba(255, 6, 6, 0.6) !important;
    }

    .success_message {
        color: green;
        font-size: 18px;
        padding: 20px 0 0;
        text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
